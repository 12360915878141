<template>
  <div class="bottom_div">
    <div class="txt_top">
      <div class="left">
        <div class="img"><img src="../../assets/news.png" alt="" /></div>
        <div class="txt">
          今日专科医生就诊328人，预防疫苗108人，健康体检123人...
        </div>
      </div>
      <div class="right">首页 > 财务</div>
    </div>
    <div class="txt_news">
      <div class="txt_news_txt">订单管理</div>
      <div class="finance_div">
        <div class="finance_div_bot">导出</div>
        <div class="finance_div_bot">新增</div>
      </div>
    </div>
    <div class="txt_bottom">
<!--      <div class="txt_bottom_nav">-->
<!--        <div class="nav_txt_off bottom_nav_txt">待确认订单</div>-->
<!--        <div class="nav_txt_off">服务中订单</div>-->
<!--        <div class="nav_txt_off">已完成订单</div>-->
<!--        <div class="nav_txt_off">已失效订单</div>-->
<!--      </div>-->
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="待确认订单" name="pending">
          <el-table
            :data="pendingList"
            style="width: 100%">
            <el-table-column
              prop="order_id"
              label="订单编号"
              width="200">
            </el-table-column>
            <el-table-column
              prop="appointment_start"
              label="预约开始时间"
              width="150">
            </el-table-column>
            <el-table-column
              prop="appointment_end"
              label="预约结束时间"
              width="150">
            </el-table-column>
            <el-table-column
              prop="appointment_type"
              label="预约类型"
              width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.appointment_type == '1'">专科医生</span>
                <span v-if="scope.row.appointment_type == '2'">健康体检</span>
                <span v-if="scope.row.appointment_type == '3'">预防疫苗</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="product_name"
              label="预约服务">
            </el-table-column>
            <el-table-column
              prop="medical_price_rmb"
              width="100"
              label="就诊费RMB">
            </el-table-column>
            <el-table-column
              prop="medical_price_hb"
              width="100"
              label="就诊费HB">
            </el-table-column>
            <el-table-column
              prop="patient_name"
              width="150"
              label="客户姓名">
            </el-table-column>
            <el-table-column
              prop="clinic_state"
              label="门诊状态">
              <template slot-scope="scope">
                <span v-if="scope.row.clinic_state == '1'">未确认</span>
                <span v-if="scope.row.clinic_state == '2'">已确认</span>
                <span v-if="scope.row.clinic_state == '3'">已拒绝</span>
              </template>

            </el-table-column>
            <el-table-column
              prop="pay_time"
              width="200"
              label="下单时间">
            </el-table-column>
            <el-table-column
              label="操作"
              width="200"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.clinic_state == '1'">
                  <el-button type="text" @click="openPass(scope.row)">确认</el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="openNoPass">拒绝</el-button>
                </template>
                <template v-if="scope.row.clinic_state == '2'">
                  <el-button type="text" @click="openChangeDate(scope.row)">修改时间</el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: flex-end;align-items: center;padding-top: 20px">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="5"
              @current-change="changePendingPage"
              :current-page="pendingPage.start"
              :total="pendingPage.totalRow">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="服务中订单" name="ing"></el-tab-pane>
        <el-tab-pane label="已完成订单" name="finish"></el-tab-pane>
        <el-tab-pane label="已失效订单" name="invalid"></el-tab-pane>
      </el-tabs>
      <el-dialog
        title="修改服务时间"
        :visible="serviceDateVisible"
        width="400px"
        :destroy-on-close="true"
        :before-close="closeServiceDateForm">
        <el-form :model="serviceForm" label-width="80px" :rules="serviceFormRules" ref="serviceForm" status-icon class="login-form">
          <el-form-item label="选择时间" prop="appointment_time">
            <el-time-select
              v-model="serviceForm.appointment_time"
              :picker-options="{
                start: serviceForm.start_time,
                step: '00:01',
                end: serviceForm.end_time
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item>
            <el-button class="primary-btn" @click="actionServiceDate" :loading="$store.state.requestLoading">确定</el-button>
            <el-button @click="resetServiceDateForm" >重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        title="确认订单"
        :visible="passDateVisible"
        width="400px"
        :destroy-on-close="true"
        :before-close="closePassDateForm">
        <el-form :model="passForm" label-width="80px" :rules="passFormRules" ref="passForm" status-icon class="login-form">
          <el-form-item label="确认时间" prop="appointment_time">
            <el-time-select
              v-model="passForm.appointment_time"
              :picker-options="{
                start: passForm.start_time,
                step: '00:01',
                end: passForm.end_time
              }"
              placeholder="确认时间">
            </el-time-select>
          </el-form-item>
          <el-form-item>
            <el-button class="primary-btn" @click="actionPassDate" :loading="$store.state.requestLoading">确定</el-button>
            <el-button @click="resetPassDateForm" >重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div class="txt_txt">
      <div class="txt_center">
        Copyright © 2020 亿立医疗顾问（深圳）有限公司 技术支持：中企高呈
      </div>
      <div class="txt_right">
        <div class="txt_right_txt"><a href="">联系客服</a></div>
        <div class="txt_right_txt"><a href="">帮助</a></div>
        <div class="txt_right_txt"><a href="">隐私</a></div>
        <div class="txt_right_txt"><a href="">条款</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from './api';
export default {
  name: "order",
  data() {
    return {
      activeName: 'pending',
      pendingPage: {
        start: 1,
        limit: 20,
        pageSize: 0,
        totalRow: 0,
      },
      pendingList: [],
      serviceDateVisible: false,
      serviceForm: {
        appointment_time: '',
        start_time: '',
        end_time: '',
        ny: '',
        id: ''
      },
      serviceFormRules: {
        appointment_time: [
          { required: true, message: '请选择修改时间', trigger: 'change' },
        ],
      },
      passDateVisible: false,
      passForm: {
        appointment_time: '',
        start_time: '',
        end_time: '',
        ny: '',
        id: ''
      },
      passFormRules: {
        appointment_time: [
          { required: true, message: '请选择确认时间', trigger: 'change' },
        ],
      }
    }
  },
  mounted() {
    this.pendingOrderPage();
  },
  methods: {
    /**
     * 获取待服务订单列表
     * @author wheat
     * */
    async pendingOrderPage () {
      let res = await Api.PendingOrderPage(this.pendingPage);
      if (res.code == '0') {
        this.pendingPage.pageSize = res['page']['pageSize'];
        this.pendingPage.totalRow = res['page']['totalRow'];
        this.pendingList = res['page']['list'];
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 打开待服务订单确认窗口
     * @author wheat
     * */
    openPass(record) {
      this.passForm.start_time = record.appointment_start.substr(11);
      this.passForm.end_time = record.appointment_end.substr(11);
      this.passForm.ny = record.appointment_end.substr(0, 11);
      this.passForm.id = record.id;
      this.passDateVisible = true;
    },
    /**
     * 待服务订单确认
     * @author wheat
     * */
    async actionPassDate() {

      this.$refs['passForm'].validate(async (valid) => {
        if (valid) {
          let params = Object.assign({}, this.passForm);
          params['appointment_time'] = params['ny'] + params['appointment_time'];
          let res = await Api.Pass(params);
          if (res.code == '0') {
            this.passDateVisible = false;
            this.pendingOrderPage();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 重置确认表单
     * @author wheat
     * */
    resetPassDateForm() {
      this.passForm.appointment_time = '';
    },
    /**
     * 关闭确认表单
     * @author wheat
     * */
    closePassDateForm() {
      this.passDateVisible = false;
    },
    /**
     * 打开待服务订单拒绝窗口
     * @author wheat
     * */
    openNoPass(row) {
      this.$confirm('拒绝当前订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.NoPass(row.id);
      }).catch(() => {
      })
    },
    /**
     * 待服务订单拒绝操作
     * @author wheat
     * */
    async NoPass(id) {
      let res = await Api.NoPass({id: id});
      if (res.code == '0') {
        this.$message.success(res.message);
        this.pendingList();
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 切换待服务订单分页
     * @author wheat
     * */
    changePendingPage(e) {
      this.pendingPage.start = e;
      this.pendingOrderPage();
    },
    /**
     * 打开修改服务时间
     * @author wheat
     * */
    openChangeDate(record) {
      this.serviceForm.start_time = record.appointment_start.substr(11);
      this.serviceForm.end_time = record.appointment_end.substr(11);
      this.serviceForm.ny = record.appointment_end.substr(0, 11);
      this.serviceForm.id = record.id;
      this.serviceDateVisible = true;
    },
    /**
     * 关闭修改服务时间
     * @author wheat
     * */
    closeServiceDateForm() {
      this.serviceDateVisible = false;
      this.serviceForm.appointment_time = '';
      this.serviceForm.start_time = '';
      this.serviceForm.end_time = '';
    },
    /**
     * 重置修改服务时间form
     * @author wheat
     * */
    resetServiceDateForm() {
      this.serviceForm.appointment_time = '';
    },
    async actionServiceDate() {
      this.$refs['serviceForm'].validate(async (valid) => {
        if (valid) {
          let params = Object.assign({}, this.serviceForm);
          params['appointment_time'] = params['ny'] + params['appointment_time'];

          let res = await Api.ChangeAppointmentTime(params);
          if (res.code == '0') {
            this.serviceDateVisible = false;
            this.pendingOrderPage();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
