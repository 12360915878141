import request from '@/common/request';
/**
 * 待服务订单列表
 */
export function PendingOrderPage(data) {
  return request({
    url: '/clinic/order/pendingOrderpage',
    method: 'GET',
    params: data
  });
}

/**
 * 确认待服务订单列表
 */
export function Pass(data) {
  return request({
    url: '/clinic/order/pass',
    method: 'POST',
    data
  });
}

/**
 * 拒绝待服务订单列表
 */
export function NoPass(data) {
  return request({
    url: '/clinic/order/noPass',
    method: 'POST',
    data
  });
}

/**
 * 修改服务时间
 */
export function ChangeAppointmentTime(data) {
  return request({
    url: '/clinic/order/changeAppointmentTime',
    method: 'POST',
    data
  });
}
